import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllUnclosedRoundService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/resolvers/unclosed-bets', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getRoundDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/rounds/get-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

// This will resolve the pending round
export const closeRoundService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/resolvers/close-bet', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
  })
}
