import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllGameUnclosedRounds,
  fetchRoundDetails
} from 'redux-thunk/thunk/gameRounds.thunk'

const initialState = {
  allGameUnclosedRounds: null,
  roundDetails: null
}

const gameRoundsSlice = createSlice({
  name: 'gameRoundsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGameUnclosedRounds.fulfilled, (state, action) => {
        return {
          ...state,
          allGameUnclosedRounds: action.payload
        }
      })
      .addCase(fetchRoundDetails.fulfilled, (state, action) => {
        return {
          ...state,
          roundDetails: action.payload
        }
      })
  }
})

export default gameRoundsSlice.reducer
