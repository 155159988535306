import { combineReducers } from '@reduxjs/toolkit'
import authSlice from './slices/auth.slice'
import hamSlice from './slices/ham.slice'
import toaster from './slices/toaster.slice'
import adminLoginLogSlice from './slices/loginLog.slice'
import siteConfigsSlice from './slices/siteConfig.slice'
import theme from './slices/theme.slice'
import loaderSlice from './slices/loader.slice'
import operatorSlice from './slices/operator.slice'
import transactionsSlice from './slices/transactions.slice'
import currencySlice from './slices/currency.slice'
import playerSlice from './slices/player.slice'
import gameSettingsSlice from './slices/gameSettings.slice'
import chatSlice from './slices/chat.slice'
import wordSlice from './slices/word.slice'
import dashboardSlice from './slices/dashboard.slice'
import gameReportsSlice from './slices/reports/gamesReport.slice'
import operatorsReportSlice from './slices/reports/operatorsReport.slice'
import playersReportSlice from './slices/reports/playersReport.slice'
import clientsReportSlice from './slices/reports/clientsReport.slice'
import registrationsReportSlice from './slices/reports/registrationsReport.slice'
import clientSlice from './slices/client.slice'
import gameRoundsSlice from './slices/gameRounds.slice'

export const rootReducer = combineReducers({
  auth: authSlice,
  ham: hamSlice,
  toaster: toaster,
  loader: loaderSlice,
  dashboard: dashboardSlice,
  players: playerSlice,
  systemMgmt: combineReducers({
    adminLoginLogs: adminLoginLogSlice
  }),
  gameSettings: gameSettingsSlice,
  transactions: transactionsSlice,
  theme: theme,
  siteConfiguration: siteConfigsSlice,
  chat: chatSlice,
  operators: operatorSlice,
  currencies: currencySlice,
  words: wordSlice,
  gamesReport: gameReportsSlice,
  operatorsReport: operatorsReportSlice,
  playersReport: playersReportSlice,
  clientsReport: clientsReportSlice,
  registrationsReport: registrationsReportSlice,
  client: clientSlice,
  gameRounds: gameRoundsSlice

})
