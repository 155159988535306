import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllUnclosedRoundService,
  getRoundDetailsService,
  closeRoundService
} from 'network/services/gameRounds.service'

export const fetchAllGameUnclosedRounds = createAsyncThunk(
  'fetch/allGameUnclosedRounds',
  async (data, thunkApi) => {
    try {
      const res = await getAllUnclosedRoundService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchRoundDetails = createAsyncThunk(
  'fetch/round-details',
  async (data, thunkApi) => {
    try {
      const res = await getRoundDetailsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const closeRoundThunk = createAsyncThunk(
  'close-round-details',
  async (data, thunkApi) => {
    try {
      const res = await closeRoundService(data)
      if (res && data.onSuccess) data.onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
